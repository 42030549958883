import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
const LogoSvg = require('../svg/logo_1.svg')

const Header = ({ siteTitle }) => (
  <header className="navbar has-shadow is-spaced is-fixed-top">
    <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item customize" to="/">
            {/* {siteTitle} */}
            <LogoSvg className="brand-logo" />
            {/* <Logo /> */}
          </Link>
        </div>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
