/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import Cookie from "./CookieBanner"
import "./layout.css"
import "../index.sass"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div id="inner-body" className="has-navbar-fixed-top magic has-background-grey-light">
      <Header siteTitle={data.site.siteMetadata.title} />
      <Cookie />
      <main id="body">{children}</main>
      <footer className="footer has-background-grey is-absolute">
        <div className="content has-text-centered">
          <p className="has-text-white">
            <Link to="/datenschutz">Datenschutz</Link> · <Link to="/impressum">Impressum</Link>
          </p>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
