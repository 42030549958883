import React from "react"
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => (
  <CookieConsent 
    containerClasses="cookie-banner has-background-black pad-20 text-center"
    contentClasses="container"
    disableStyles={true}
    buttonClasses="button"
    buttonText="Okay"
  >
    <p class="has-text-white show-mobile cookie-text">Wir benutzen Cookies.</p>
    <p class="has-text-white show-desktop cookie-text">Auch unsere Website funktioniert nicht ohne Cookies. Das ist nichts Schlimmes – sie ermöglichen ein lückenloses Erlebnis unseres Angebots.</p>
  </CookieConsent>
)

export default CookieBanner
